import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Metadata = ({ pageTitle }) => {
  const { t } = useTranslation();
  // TODO: add meta tag after title for SEO (meta description)

  if (pageTitle === 'index') {
    return (
      <Helmet>
        <title>{t('Metadata.index')}</title>
        <meta name="description" content={t('Metadata.description.index')} />
      </Helmet>
    );
  }
  return (
    <Helmet>
      <title>
        {pageTitle} | {t('Metadata.siteName')}
      </title>
    </Helmet>
  );
};

export default Metadata;
