import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import HideOnScroll from './HideOnScroll';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'rgba(186, 186, 186, 0.9)',
  },

  toolBar: {
    justifyContent: 'space-between',
    color: '#e42229',
  },
});

const CustomAppBar = ({ children, customClass }) => {
  const classes = useStyles();

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        classes={{ root: classes.appBar }}
        className={customClass}
      >
        <Toolbar variant="dense" classes={{ root: classes.toolBar }}>
          {children}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default CustomAppBar;
