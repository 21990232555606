import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import Iran from '../images/iran.svg';
import UnitedKingdom from '../images/united-kingdom.svg';
import US from '../images/us.svg';
import { EN, FA } from '../constants';

const useStyles = makeStyles({
  flagWrapper: {
    display: 'flex',
    borderRadius: '50%',
  },

  flag: {
    opacity: 0.4,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
});

const Flag = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const currentLang = i18n.language;

  const handleChangeLang = (selectedLang) => {
    changeLanguage(selectedLang);
  };

  const iranFlag = (
    <img
      width={30}
      alt={t('Flag.Persian')}
      title={t('Flag.Persian')}
      src={Iran}
      style={currentLang === FA ? { opacity: 1 } : {}}
      className={`event-img ${classes.flag}`}
    />
  );

  const engFlag = (
    <img
      width={30}
      alt={t('Flag.English')}
      title={t('Flag.English')}
      src={UnitedKingdom}
      style={currentLang === EN ? { opacity: 1 } : {}}
      className={`event-img ${classes.flag}`}
    />
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Box
          m={1}
          className={classes.flagWrapper}
          onClick={() => handleChangeLang(FA)}
        >
          {iranFlag}
        </Box>
      </Grid>
      <Grid item>
        <Box
          m={1}
          className={classes.flagWrapper}
          onClick={() => handleChangeLang(EN)}
        >
          {engFlag}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Flag;
