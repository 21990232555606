import React, { useState } from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import Typography from '@material-ui/core/Typography';

import Flag from './Flag';
import CustomAppBar from './CustomAppBar';

const menuItems = [
  {
    text: 'Menu.Home',
    route: '/',
  },
  {
    text: 'Menu.About',
    route: '/about',
  },
  {
    text: 'Menu.Abilities',
    route: '/abilities',
  },
  {
    text: 'Menu.Projects.text',
    route: '/projects',
  },
  {
    text: 'Menu.Contact',
    route: '/contact',
  },
  {
    text: 'Menu.Price',
    route: '/price',
  },
];
const copyright = 'copyright';
const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    background: '#717171',
    color: '#fff',
    width: 230,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: '0 0 25px 25px',
    },
  },

  navBar: {
    [theme.breakpoints.up('md')]: {
      width: 230,
    },
  },

  logoSet: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
  },

  logo: {
    width: '100%',
  },

  menuItem: {
    justifyContent: 'center',
    marginTop: 10,
  },

  subMenuItem: {
    justifyContent: 'center',
  },

  appBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  active: {
    backgroundColor: '#926C6D',
  },

  topDrawer: {
    paddingRight: 24,
    paddingLeft: 24,
    color: '#e42229',
    [theme.breakpoints.up('md')]: {
      '& .MuiIconButton-root': {
        display: 'none',
      },
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
}));

const Menu = ({ window }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const Logo = '../images/full-logo.png';

  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const logoSet = (
    <Box mx={2} my={1} className={classes.logoSet}>
      <Link to="/" title="Micron">
        <StaticImage
          src={Logo}
          alt="Micron"
          placeholder="blurred"
          className={classes.logo}
        />
      </Link>
    </Box>
  );

  const drawer = (
    <Grid container direction="column" className="flexOne">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.topDrawer}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <CloseRoundedIcon />
        </IconButton>
        {logoSet}
      </Grid>
      <Divider />
      <Grid item>
        <Flag />
      </Grid>
      <Grid item className="flexOne">
        <MenuList variant="menu">
          {menuItems.map((item) => {
            if (item.subMenus !== undefined) {
              return (
                <div key={item.text}>
                  <MenuItem
                    onClick={handleOpen}
                    component={Link}
                    to={item.route}
                    activeClassName={classes.active}
                    classes={{ root: classes.menuItem }}
                  >
                    {t(item.text)}
                    {open ? (
                      <ExpandLessRoundedIcon />
                    ) : (
                      <ExpandMoreRoundedIcon />
                    )}
                  </MenuItem>
                  <Collapse in={open} timeout="auto">
                    <Divider />
                    <MenuList disablePadding>
                      {item.subMenus.map((subMenu) => (
                        <MenuItem
                          key={subMenu.text}
                          component={Link}
                          to={subMenu.route}
                          activeClassName={classes.active}
                          classes={{ root: classes.subMenuItem }}
                          dense
                        >
                          {t(subMenu.text)}
                        </MenuItem>
                      ))}
                    </MenuList>
                    <Divider />
                  </Collapse>
                </div>
              );
            } else {
              return (
                <MenuItem
                  key={item.text}
                  component={Link}
                  to={item.route}
                  activeClassName={classes.active}
                  classes={{ root: classes.menuItem }}
                >
                  {t(item.text)}
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Grid>
      <Grid item>
        <Box mt={2} className="textCenter ltr">
          <Typography variant="caption">
            &copy; {currentYear}
            {t(copyright)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      <CustomAppBar customClass={classes.appBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuRoundedIcon />
        </IconButton>
        {logoSet}
      </CustomAppBar>

      <nav className={classes.navBar}>
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="top"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default Menu;
