import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Menu from './Menu';
import { EN } from '../constants';
import { theme, rtlTheme } from '../styles/theme';

const direction = 'assets.css.direction';
const fontFamily = 'assets.css.fontFamily';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  main: {
    flex: 1,
    position: 'relative',
  },
}));

const Template = ({ children }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const currentLng = i18n.language;
  const _theme = currentLng === EN ? theme : rtlTheme;

  useEffect(() => {
    let style = document.createElement('style');
    style.innerHTML = `
      *:not(ul) {
        direction: ${t(direction)};
      }

      * {
        font-family: ${t(fontFamily)};
      }
    `;
    document.head.appendChild(style);
    document.body.setAttribute('dir', t(direction));
  }, [t, i18n]);

  return (
    <ThemeProvider theme={_theme}>
      <div className={classes.root}>
        <Menu />
        <main className={classes.main}>{children}</main>
      </div>
    </ThemeProvider>
  );
};

export default Template;
