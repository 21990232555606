import { createTheme } from '@material-ui/core/styles';

import { LTR, RTL } from '../constants';

const themeColor = '#e42229';
const textColor = '#5a5a5a';
const secondaryColor = 'rgba(0, 0, 0, 0.54)';
const secondaryColorBold = 'rgba(0, 0, 0, 0.87)';

export let theme = createTheme({});
const themeOverrides = {
  MuiPaper: {
    rounded: {
      borderRadius: 25,
      padding: 10,
    },
  },

  MuiDivider: {
    root: {
      marginBottom: 10,
    },
  },

  MuiInput: {
    input: {
      fontWeight: 300,
    },
    underline: {
      '&:after': {
        borderBottomColor: themeColor,
      },
    },
  },

  MuiFormLabel: {
    root: {
      fontSize: 14,
      '&$focused': {
        color: themeColor,
      },
    },
  },

  MuiCheckbox: {
    root: {
      color: textColor,
    },
    colorSecondary: {
      '&$checked': {
        color: themeColor,
      },
    },
  },

  MuiSelect: {
    select: {
      color: textColor,
      fontSize: 14,
      '&:focus': {
        backgroundColor: 'none',
      },
    },
  },

  MuiButton: {
    root: {
      color: themeColor,
      textTransform: 'none',
      borderRadius: 25,
    },
    outlined: {
      borderColor: themeColor,
    },
    textSecondary: {
      color: secondaryColor,
    },
    outlinedSecondary: {
      color: secondaryColor,
      borderColor: secondaryColor,
      '&:hover': {
        borderColor: secondaryColorBold,
      },
    },
  },

  MuiTypography: {
    h6: {
      fontSize: 20,
      fontWeight: 300,
      marginRight: 8,
      marginLeft: 8,
      color: themeColor,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    h5: {
      paddingTop: 5,
      paddingBottom: 5,
      color: textColor,
      [theme.breakpoints.down('md')]: {
        paddingTop: 3,
        paddingBottom: 3,
        fontSize: '1.3rem',
      },
    },
    h4: {
      color: textColor,
      fontWeight: 500,
      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    body1: {
      color: textColor,
      fontWeight: 400,
      lineHeight: 1.6,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    body2: {
      fontWeight: 300,
      color: textColor,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
      '& a': {
        color: textColor,
        textDecoration: 'none',
      },
    },
    caption: {
      fontWeight: 300,
      color: '#b1b1b1',
    },
    overline: {
      color: '#5cb85c',
    },
  },

  MuiListItem: {
    root: {
      textAlign: 'inherit',
    },
  },

  MuiFormHelperText: {
    root: {
      textAlign: 'inherit',
    },
  },
};

theme = {
  ...theme,
  direction: LTR,
  overrides: {
    ...themeOverrides,
  },
};

export const rtlTheme = {
  ...theme,
  direction: RTL,
  overrides: {
    ...themeOverrides,
    MuiInputLabel: {
      formControl: {
        right: 0,
        left: 'auto',
      },
      shrink: {
        transformOrigin: 'top right',
      },
    },

    MuiSelect: {
      select: {
        ...themeOverrides.MuiSelect.select,
        '&&': {
          paddingRight: 0,
          paddingLeft: 24,
        },
      },
      icon: {
        left: 0,
        right: 'auto',
      },
    },

    MuiButton: {
      ...themeOverrides.MuiButton,
      endIcon: {
        marginLeft: -4,
        marginRight: 8,
      },
      startIcon: {
        marginRight: -4,
        marginLeft: 8,
      },
    },
  },
};
